import React from "react"
import { useTranslation, Trans } from "react-i18next"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import "../components/i18n"

import logo from "../images/logo.svg"
import clip from "../images/play-cogito-solitaire-1.mp4"

export default () => {
  const { t, i18n } = useTranslation()
  return (
    <Layout title={t("title")}>
      <SEO title={t("home.title")} />
      <img
        src={logo}
        alt="Cogito Solitaire"
        style={{
          float: "left",
          display: "block",
          padding: "10px",
          paddingLeft: "0",
        }}
      />
      <div>
        <p>
          <Trans i18nKey="home.p1" />
        </p>
        <p>
          <Trans i18nKey="home.p2" />
        </p>
        <p>
          {t("home.p3.split1")} {" "}
          <Link to="/help">{t("help.title")}</Link>
          {" "}{t("home.p3.split2")}
        </p>
        <video src={clip} width="100%" controls></video>
      </div>
    </Layout>
  )
}
